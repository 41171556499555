import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Consulting`}</h2>
    <p>{`I led a group of food startups organized in Quito's Chamber of Commerce to develop a product line for the tourism industry. We market researched, worked with a graphic design team and prepared various product concepts for market testing and launch. It allowed all the companies to learn more about the tourism industry and also prepare specific offerings for each. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/68c11e27d4f20e978fce35e893b0e86e/bcec7/albums2_18.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAA//aAAwDAQACEAMQAAAB5fRyvhizYuO02pQF/8QAGxAAAgMBAQEAAAAAAAAAAAAAAQIAAxEhEhP/2gAIAQEAAQUCC+iEyMqbT1reT6CKclrEif/EABgRAAIDAAAAAAAAAAAAAAAAABAREiJB/9oACAEDAQE/AZ2Q0f/EABkRAAEFAAAAAAAAAAAAAAAAABABAhEhQf/aAAgBAgEBPwFW1Iwf/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQAQEUECITH/2gAIAQEABj8CQxI40KabVFBv/8QAGxABAQEBAAMBAAAAAAAAAAAAAREAMSFBgVH/2gAIAQEAAT8hkll5qAP07oeMZfTm36oOvmTSMBMSL4nPhjnd/9oADAMBAAIAAwAAABCkNwP/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEQ/9oACAEDAQE/EBN4yGef/8QAFxEBAQEBAAAAAAAAAAAAAAAAEQABEP/aAAgBAgEBPxAijZ4//8QAHhABAAICAgMBAAAAAAAAAAAAAQARIUFhkTFxsfD/2gAIAQEAAT8Qo0W0tcxdaY7Lsz6Y+9lXgYaxgwHGiA1iiLrLs6gC/rKuUMU1ncaSINHH4XuFSq6J/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Kamuy",
            "title": "Kamuy",
            "src": "/static/68c11e27d4f20e978fce35e893b0e86e/4b190/albums2_18.jpg",
            "srcSet": ["/static/68c11e27d4f20e978fce35e893b0e86e/e07e9/albums2_18.jpg 200w", "/static/68c11e27d4f20e978fce35e893b0e86e/066f9/albums2_18.jpg 400w", "/static/68c11e27d4f20e978fce35e893b0e86e/4b190/albums2_18.jpg 800w", "/static/68c11e27d4f20e978fce35e893b0e86e/bcec7/albums2_18.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5de95937291d8ba795faca4722bf50b6/bcec7/albums2_17.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEAQL/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHR2FOe5MRfUipg/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECAxIRE//aAAgBAQABBQKI0ho9TpZbLcjTQj//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BK//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwEj/8QAGBABAAMBAAAAAAAAAAAAAAAAEAERITH/2gAIAQEABj8C1qTDp//EABkQAQEBAQEBAAAAAAAAAAAAAAEAITERYf/aAAgBAQABPyE57xM+S+9IA8EkvNgIeEPYXitF/9oADAMBAAIAAwAAABBnCDz/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/EHSt/8QAFhEBAQEAAAAAAAAAAAAAAAAAEAEx/9oACAECAQE/EBcP/8QAHBABAAIDAQEBAAAAAAAAAAAAAQARITFBUXGR/9oACAEBAAE/EL62Gh7L3fw4gMHPyYreDyAisujqW7Wq4RpOXiVLUdw2KtrP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Kamuy",
            "title": "Kamuy",
            "src": "/static/5de95937291d8ba795faca4722bf50b6/4b190/albums2_17.jpg",
            "srcSet": ["/static/5de95937291d8ba795faca4722bf50b6/e07e9/albums2_17.jpg 200w", "/static/5de95937291d8ba795faca4722bf50b6/066f9/albums2_17.jpg 400w", "/static/5de95937291d8ba795faca4722bf50b6/4b190/albums2_17.jpg 800w", "/static/5de95937291d8ba795faca4722bf50b6/bcec7/albums2_17.jpg 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      